const FooterData = [
  // {
  //     title: "company",
  //     submenu: [
  //         {
  //             title: "About company",
  //             link: "/page/about-us"
  //         },
  //         {
  //             title: "Company services",
  //             link: "/page/our-services"
  //         },
  //         {
  //             title: "Job opportunities",
  //             link: "/page/our-team"
  //         },
  //         {
  //             title: "Contact us",
  //             link: "/page/contact-classic"
  //         },
  //     ]
  // },
  {
    title: "Policy",
    submenu: [
      {
        title: "Privacy policy",
        link: "privacy",
      },
      {
        title: "Terms and Condition",
        link: "terms-and-conditions",
      },
      // {
      //   title: "Company history",
      //   link: "",
      // },
      // {
      //   title: "Our process",
      //   link: "",
      // },
    ],
  },
  // {
  //     title: "Additional",
  //     submenu: [
  //         {
  //             title: "Our story",
  //             link: "/page/our-story"
  //         },
  //         {
  //             title: "Who we are",
  //             link: "/page/who-we-are"
  //         },
  //         {
  //             title: "Our process",
  //             link: "/page/our-process"
  //         },
  //         {
  //             title: "Latest news",
  //             link: "/page/latest-news"
  //         },
  //     ]
  // },
  // {
  //     title: "Services",
  //     submenu: [
  //         {
  //             title: "Brand experience",
  //             link: "/page/our-services"
  //         },
  //         {
  //             title: "e-Commerce website",
  //             link: "/page/our-services"
  //         },
  //         {
  //             title: "Content writing",
  //             link: "/page/our-services"
  //         },
  //         {
  //             title: "Marketing strategy",
  //             link: "/page/our-services"
  //         },
  //     ]
  // },
  // {
  //     title: "Resources",
  //     submenu: [
  //         {
  //             title: "Theme guide",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "Support desk",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "What we offer",
  //             link: "/page/what-we-offer"
  //         },
  //         {
  //             title: "Company history",
  //             link: "/page/our-story"
  //         },
  //     ]
  // },
  // {
  //     title: "Categories",
  //     submenu: [
  //         {
  //             title: "For men",
  //             link: "/shop/shop-wide"
  //         },
  //         {
  //             title: "For woman",
  //             link: "/shop/shop-wide"
  //         },
  //         {
  //             title: "Accessories",
  //             link: "/shop/shop-wide"
  //         },
  //         {
  //             title: "Collections",
  //             link: "/shop/shop-wide"
  //         },
  //     ]
  // },
  // {
  //     title: "Adavantages",
  //     submenu: [
  //         {
  //             title: "Free delivery",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "100 days refund",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "Multiple payments",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "Sustainable",
  //             link: "/page/faq-s"
  //         },
  //     ]
  // },
];

export default FooterData;
