import React, { memo } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

// Components
import SocialIcons from "../SocialIcon/SocialIcons";
import FooterMenu, { Footer } from "./Footer";

// Data
import FooterData from "./FooterData";
const iconData = [
  // {
  //   color: "#828282",
  //   link: "",
  //   icon: "fab fa-facebook-f",
  // },
  // {
  //   color: "#828282",
  //   link: "",
  //   icon: "fab fa-dribbble",
  // },
  // {
  //   color: "#828282",
  //   link: "",
  //   icon: "fab fa-twitter",
  // },
  // {
  //   color: "#828282",
  //   link: "h",
  //   icon: "fab fa-instagram",
  // },
];

const FooterStyle08 = (props) => {
  return (
    <Footer
      className={`${props.className ? ` ${props.className}` : ""}`}
      theme={props.theme}
    >
      <div className="py-[5%] lg:py-[8%] sm:py-[50px]">
        <Container>
          <Row className="justify-between md:justify-start">
            <Col
              lg={{ span: 3, order: 0 }}
              md={5}
              sm={{ span: 6, order: 1 }}
              className="md:mb-[50px] xs:mb-[25px]"
            >
              {/* <Link aria-label="link" to="/" className="mb-[30px] block"> */}
              <img
                src="/assets/yumado/images/home/landing/logo%2002.webp"
                alt="logo"
                width="111"
                height="36"
                className="mb-[30px] block"
              />
              {/* </Link> */}
              <p className="w-[95%] lg:w-full mb-[25px]">
                Yumado, a food delivery app in Jamshedpur, shall be serving
                soon!
              </p>
              <SocialIcons
                theme="social-icon-style-01"
                className="justify-start"
                size="xs"
                iconColor={props.theme === "dark" ? "light" : "dark"}
                data={iconData}
              />
            </Col>
            <FooterMenu
              data={FooterData.slice(0, 3)}
              lg={{ span: "auto" }}
              md={{ span: 3 }}
              sm={6}
              xs={{ order: 2 }}
              className="md:mb-[40px] xs:mb-[25px]"
              titleClass="capitalize"
            />
            <Col
              lg={{ span: "auto", offset: 0 }}
              md={{ span: "auto", offset: 2 }}
              sm={{ order: 3 }}
              xs={{ span: 12, order: 3 }}
              className="lg:text-start"
            >
              <span className="mb-[30px] font-medium text-themecolor font-serif block sm:hidden">
                Download for free
              </span>
              <div className="flex flex-col sm:flex-row xs:text-center xs:block justify-between">
                <Link
                  aria-label="link"
                  className="inline-block mb-[15px] sm:mb-0 xs:mb-[15px]"
                  to="#"
                >
                  <img
                    className="w-full"
                    width={223}
                    height={71}
                    src="assets/yumado/images/home/landing/appstore-download-button.webp"
                    alt="app-store"
                  />
                </Link>
                <Link
                  aria-label="link"
                  className="inline-block mb-[15px] sm:mb-0 xs:mb-[15px]"
                  to="#"
                >
                  <img
                    className="w-full"
                    width={223}
                    height={71}
                    src="assets/yumado/images/home/landing/play-store-download-button.webp"
                    alt="play-store"
                  />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="p-[35px] border-t border-[#ffffff1a] xs:py-[35px] xs:px-0">
        <Container>
          <Row>
            <Col
              md={6}
              className="md:mb-[0.75rem] xs:mb-[15px] xs:items-center sm:mb-[15px]"
            >
              <p>
                &copy; {new Date().getFullYear()} Yumado | All Rights Reserved.
              </p>
            </Col>
            <Col
              md={6}
              className="justify-end flex gap-2 sm:justify-center text-center md:text-end"
            >
              <div> Developed By |</div>

              <a
                aria-label=""
                target="_blank"
                rel="noreferrer"
                href="https://teckat.com/"
                className="text-themecolor flex gap-2 items-center"
              >
                <img
                  src="assets/yumado/images/home/landing/footer-icon-teckat"
                  alt=""
                  className="w-[25px] h-auto"
                />
                <span className="hover:text-[#fb9b19]">Teckat.com</span>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </Footer>
  );
};

FooterStyle08.defaultProps = {
  data: FooterData,
  logo: "/assets/img/webp/logo-white.webp",
};

FooterStyle08.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
};

export default memo(FooterStyle08);
